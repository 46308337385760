.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: rgba(245, 246, 247, 0.45);
  color: #212529;
} 

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  text-align: center;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}


body {
  font: 20px "Montserrat", sans-serif;
  line-height: 1.8;
  background-color: #f5f6f7;
}
p {
  font-size: 16px;
}
.main {
  background-image: url('../images/wine-chairs.jpg');
  background-size: cover;
  
}
.margin {
  margin-bottom: 45px;
}
.bg-0 {
  background-color: rgba(255,255,255,0.0);
  color: #111111;
}
.bg-1 {
  background-color: #f5f6f7;
  color: 474e5d;
}
.bg-2 {
  background-color: #66a5ad;
  color: #222222;
}
.bg-3 {
  background-color: #07575b;
  color: #eeeeee;
}
.bg-4 {
  background-color: #003b46;
  color: #ffffff;
}
.bg-5 {
  background-color: #2f2f2f;
  color: #ffffff;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
.navbar {
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 5px;
}
.navbar-nav:hover li a:hover {
  color: #1abc9c !important;
}
.nav-link {
  color: #212529;
}

.card {
  padding-bottom: 5
}