.section {
display: "flex";
flex-direction: "column";
flex-grow: 1;
min-height: "100%";
overflow: "hidden"
}

.main {
display: "flex";
flex-direction: "column";
align-items: "stretch";
flex-shrink: 0;
position: "relative";
padding: 0;
order: 4
}

.childrenWrapper {
padding-top: 30;
display: "flex";
margin: "0 auto";
flex-flow: "row nowrap";
max-width: "935px !important"
}

.children {
width: "100%"
}

.container {
    max-width: 100%
}