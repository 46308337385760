body {
    font: 20px "Montserrat", sans-serif;
    line-height: 1.8;
    background-color: #f5f6f7;
}

p {
    font-size: 16px;
}

h3 {
    margin-bottom: 30px;
}

h2 {
    margin-bottom: 30px;
}